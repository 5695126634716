import './Menu.css';
import {Container, Image, Nav, Navbar} from "react-bootstrap";
import {FaEnvelope, FaFileAlt, FaHome, FaUser} from "react-icons/fa";
export default function Menu(){
    return (
        <Navbar  bg="dark" variant="dark" expand="lg" fixed="top">
            <Container>
                <Navbar.Brand href="#home">

                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="ml-auto">
                        <Nav.Link href="#home"><FaHome /> Home</Nav.Link>
                        <Nav.Link href="https://learn.microsoft.com/en-us/users/asif-ashraf/transcript/vpemlc2n1zx8qkr" target={'_blank'}><FaUser /> Microsoft Profile</Nav.Link>
                        <Nav.Link href="/asif-ashraf-ms-transcript.htm"><FaFileAlt /> Transcript</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}
