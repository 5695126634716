import React, { useEffect } from 'react';
import './Layout.css';
import Menu from './Menu';
import {Col, Container, Row} from "react-bootstrap";
export default function Layout({ children }){
    const prod = process.env.REACT_APP_PROD === 'true';
    useEffect(() => {
            if (prod && window.location.protocol === 'http:') {
                window.location.href = window.location.href.replace(/^http:/, 'https:');
            }
        }, []);

    if(prod && window.location.protocol === 'http:'){
        return <div>Redirecting to secure link...</div>;
    }

    return (
        <div>
            <Menu></Menu>
            <br/><br/><br/>
            <Container>
                <Row>
                    <Col>
                        {children}
                    </Col>
                </Row>

            </Container>
        </div>
    );
}
