import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";

import 'bootstrap/dist/css/bootstrap.min.css';

import Layout from "./pages/Layout";
import Home from './pages/Home/Home';
import About from './pages/About/About';
const prod = process.env.REACT_APP_PROD === 'true';

const router = createBrowserRouter([
    {
        path: "/",
        element: <Home></Home>,
    },
    {
        path: "/about",
        element: <About></About>,
    },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <Layout>
          <RouterProvider router={router} />
      </Layout>

  </React.StrictMode>
);



if(prod){
    reportWebVitals();
}


