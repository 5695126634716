import './Home.css';
import {Col, Row, Image} from "react-bootstrap";
export default function home() {
    return <div>
            <div>
                <Row>
                    <Col>
                        <ImageHeading path="/img/asif-arch.png" title="Asif Ashraf" width="250"></ImageHeading>
                    </Col>
                    <Col>
                        <img src="img/cert-logos.png" title="Certifications" style={{ width: '400px', float: 'right', marginTop: 50 }} />
                        <Image src="/img/emails.png" style={{width: 400, float: 'right'}}></Image>
                    </Col>
                </Row>

                <br />


                <div className="row">
                    <div className="col-md-6">
                        <div className="card mb-3">
                            <div className="card-body">
                                <h5 className="card-title">Verify Microsoft Transcript (Official)</h5>
                                <p className="card-text">History of my certifications on Official website of Microsoft</p>
                                <a href="https://learn.microsoft.com/en-us/users/asif-ashraf/transcript/vpemlc2n1zx8qkr" target="_blank" rel="noopener noreferrer">Verify on official Microsoft Website</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="card">
                            <div className="card-body">
                                <h5 className="card-title">Microsoft Transcript Locally Hosted</h5>
                                <p className="card-text">Copy of Microsoft Certifications</p>
                                <a href="/asif-ashraf-ms-transcript.htm" target="_blank" rel="noopener noreferrer">Microsoft transcript (Locally hosted)</a>
                            </div>
                        </div>
                    </div>
                </div>




                <ImageHeading path="/img/github.png" title="Skills" width="200"></ImageHeading>
                <a href="https://github.com/asifashraf">Asif Ashraf: github.com/asifashraf</a>
                <br/>
                <ImageHeading path="/img/skills.png" title="Skills" width="150"></ImageHeading>

                <div>
                    <b>Languages</b> : C#, Javascript(Node.js), TypeScript <br/>
                    <b>Web</b> : ASP.NET, MEAN, MERN, Laravel, Next.js<br/>
                    <b>Frontend & JS</b> : React, Vue.JS, Angular<br/>
                    <b>Cloud</b> : Amazon AWS, Microsoft Azure<br/>
                    <b>Databases</b> : MS SQL server, MySQL, Postgresql, MongoDB, DynamoDB<br/>
                    <b>Docker</b> : Docker, Docker compose, Kubernetes<br/>
                    <b>Web Server Management</b> : Apache, Nginx, IIS<br/>
                    <b>Agile</b> : Agile/Scrum, Jira, Confluence<br/>
                    <b>Devops</b> : CI & CD, Github Actions, Bitbucket Pipelines, Gitlab runners<br/>
                    <b>Architectures</b> : Microservices, Domain Driven Design, CQRS, Event Sourcing, Monolith<br/>

                </div>
            </div>


        <div>
            <ImageHeading path="/img/dev-2024.png" title="Skills" width="420"></ImageHeading>
            <Skill
                link="https://www.humanity.com"
                linkTitle="Humanity"
                position="Senior Developer / Architect Full stack"
                techStack="ASP.NET, C#, PHP Laravel, Java, MS SQL server, MySql, React, Angular, Amazon AWS, Microsoft Azure"
                desc=""
            ></Skill>
            <Skill
                link="https://www.swvl.com"
                linkTitle="SWVL"
                position="MERN Developer, Full stack"
                techStack="MongoDB, Node.js, JavaScript, TypeScript, Kubernetes, DataDog"
                desc=""
            ></Skill>
            <Skill
                link="https://www.designpro.ai/"
                linkTitle="DesignPro"
                position="Senior Developer & Architect & Co-Devops"
                techStack="ASP.NET MVC, MSSQL Server, Python, Django, Postgresql, React, TypeScript, Angular"
                desc=""
            ></Skill>

            <ImageHeading path="/img/prod-2024.png" title="Skills" width="500"></ImageHeading>
            <Skill
                link="https://inspectionmanager.com"
                linkTitle="Inspeciton Manager"
                position="Technical Leader"
                techStack="C#, ASP.NET MVC, MS SQL Server, react.js | Angular"
                desc="Real estate inspections | Large monolith on Amazon AWS, 4TB MS SQL DB, 200GB media induction per day"
            ></Skill>

            <Skill
                link="https://propertyspace.com/"
                linkTitle="Property Space"
                position="Lead Developer and Architect"
                techStack="C#, ASP.NET MVC, MS SQL Server, VUE.JS"
                desc="Real estate leasing | Large Micr-services architecture, docker-compose, kubernetes"
            ></Skill>
            <Skill
                link="https://zurple.com/"
                linkTitle="Zurple"
                position="Senior Developer"
                techStack="C#, ASP.NET MVC, MS SQL Server, react.js | Angular"
                desc="Property advertisement on facebook, Insta and youtube"
            ></Skill>
            <Skill
                link="https://z57.com/"
                linkTitle="Z57"
                position="Senior Lead Developer | Scrum Master"
                techStack="C#, ASP.NET MVC, MS SQL Server, vue.js | Angular"
                desc="Property advertisement on facebook, Insta and youtube"
            ></Skill>

            <ImageHeading path="/img/prod-2015.png" title="Skills" width="500"></ImageHeading>
            <Skill
                link="https://www.mycastingnet.com/"
                linkTitle="My Casting Net"
                position="Senior SQL Server DBA & Backend .NET Developer"
                techStack="ASP.NET MVC, MS SQL Server"
                desc=""
            ></Skill>
            <Skill
                link="https://www.blanklabel.com/"
                linkTitle="Blank Label"
                position="Fullstack .NET Developer"
                techStack="ASP.NET MVC, MS SQL Server"
                desc=""
            ></Skill>
            <Skill
                link="https://easystorecreator.com/"
                linkTitle="Technical Lead"
                position="Lead .NET Developer, Full stack"
                techStack="ASP.NET MVC, MS SQL Server"
                desc=""
            ></Skill>



        </div>

<br/><br/><br/><br/>



    </div>
}

function Skill({link, linkTitle, position, techStack, desc}){
    return (<p>
        <a href={link}>{linkTitle}</a>
        <br/>
        <b>{position}: </b>
        {techStack}
        <br/>
        {desc}
    </p>);
}


function ImageHeading({path, title, width}){
    return <p>
        <img src={path} alt={title} style={{ width: `${width}px`, height: 'auto', marginTop: 50 }}/>
    </p>
}
